@font-face {
  font-family: "AeonikRegular";
  src: url("../fonts/Aeonik-Regular.eot");
  src: url("../fonts/Aeonik-Regular.woff2") format("woff2"),
    url("../fonts/Aeonik-Regular.otf") format("opentype");
}

@font-face {
  font-family: "AeonikMedium";
  src: url("../fonts/Aeonik-Medium.eot");
  src: url("../fonts/Aeonik-Medium.woff2") format("woff2"),
    url("../fonts/Aeonik-Medium.otf") format("opentype");
}

@font-face {
  font-family: "AeonikBold";
  src: url("../fonts/Aeonik-Bold.eot");
  src: url("../fonts/Aeonik-Bold.woff2") format("woff2"),
    url("../fonts/Aeonik-Bold.otf") format("opentype");
}

.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6,
.MuiTypography-subtitle1,
.MuiTypography-subtitle2,
.MuiButton-text,
.MuiButton-contained,
.MuiButton-root,
.MuiToggleButton-root,
.MuiDataGrid-columnHeaderTitle {
  font-family: "AeonikBold", Arial, sans-serif;
}

.MuiChip-label {
  font-family: "AeonikMedium", Arial, sans-serif;
}
*,
.MuiTypography-body1,
.MuiTypography-body2,
.MuiTypography-h3,
.MuiInputLabel-root,
.MuiFormHelperText-root,
.MuiFilledInput-input,
.MuiMenuItem-root {
  font-family: "AeonikRegular", Arial, sans-serif;
}
.MuiTypography-subtitle2,
.MuiToggleButton-sizeSmall {
  font-size: 0.875rem;
}

/***tables grid***/
.MuiDataGrid-cellContent {
  cursor: pointer;
}

/***dashboard table***/
.Dashboard .MuiDataGrid-root {
  box-shadow: none;
  border: 1px solid #ccc;
}

/***Stops the border being placed around rich text editors***/
.ProseMirror:focus {
  outline: none;
}

/**Deal Summary**/
.deal-status-bar {
  background-color: #001b42;
  padding: 16px 16px 0 16px;
  border-radius: 10px;
  margin-bottom: 24px;
}
.deal-status-bar h6 {
  color: #fff;
}

/**Tenancy Delivery Summary **/

.tenancy-status-bar {
  background-color: rgba(0, 27, 66, 0.08);
  padding: 16px 16px 0 16px;
  border-radius: 10px;
  margin-bottom: 24px;
}
.tenancy-status-bar h6 {
  color: #001b42;
}

.phase-highlight {
  background-color: #679cee;
  margin-top: 16px;
  height: 4px;
  width: 100%;
}
